@import "../../../scss/variables";

.sidenav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: white;
  overflow-x: hidden;
  padding-top: 20px;
  transition: 0.5s;
  box-shadow: 0px 2px 11px 0px rgba(147, 147, 147, 0.2);
  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  .MuiList-root {
    margin-top: 7em;
    // .MuiSvgIcon-root {
    //   color: white;
    // }
  }
  .sidemenu-logo {
    height: 5em;
  }

  .MuiSvgIcon-root {
    margin-left: 0.3em;
    color: $primaryColor;
  }


  .MuiListItemIcon-root {
    min-width: 40px !important;
    color: $primaryColor;
  }
  .MuiTypography-body1 {
    color: $primaryColor;
    font-size: 13px !important;
    font-family: Montserrat-Medium;
  }

  .listItem {
    width: 90%;
    margin: 0 auto;
    &:hover,
    &.Mui-selected,
    &.Mui-selected:hover {
      border: 0 !important;
      background: $secondaryColor !important;
      color: $primaryColor;
      border-radius: 5px;
      box-shadow: 0px 1px 17px 0px rgba(127, 50, 63, 0.22);
      .MuiTypography-body1 {
        color: white !important;
      }
    }
  }

  .curvedNav {
    position: fixed;
    left: 16em;
    right: auto;
    top: 5em;
    transition: 0.5s;
    display: inline-block;
    .openBtn {
      display: none;
      left: 0.4em;
      position: absolute;
      top: 2.2em;
      z-index: 999;
      background-color: rgba(234, 134, 133, 0.25);
      height: 38px;
      width: 20px;
      padding: 0;
      text-align: center;
      transition: 0.9s;
      border-radius: 90px 0 0 90px;
      .MuiSvgIcon-root {
        margin-left: 0.2em;
        color: $primaryColor;
      }
    }
    .closebtn {
      left: 0.4em;
      position: absolute;
      top: 2.2em;
      z-index: 999;
      background-color: rgba(234, 134, 133, 0.25);
      height: 38px;
      width: 20px;
      padding: 0;
      text-align: center;
      transition: 0.5s;
      border-radius: 90px 0 0 90px;
      .MuiSvgIcon-root {
        margin-left: 0.3em;
        color: $primaryColor;
      }
    }
    }


  .logoSideNav {
    position: absolute;
    bottom: 0;
  }
  .icons_img {
    width: 2em;
    height: 2em;
  }
}

.MuiListItem-button:hover {
  border-right: none;
  border-left: 2px solid white;
  background: linear-gradient(
    250deg,
    rgba(74, 144, 226, 0) 0%,
    rgba(255, 255, 255, 0.19) 100%
  );
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  border-right: none;
  border-left: 2px solid white;
  background: linear-gradient(
    250deg,
    rgba(74, 144, 226, 0) 0%,
    rgba(255, 255, 255, 0.19) 100%
  );
}


.rtl {
  .sidenav {
    right: 0 !important;
    left: auto !important;
    .curvedNav {
      right: 16em;
      left: auto;
      top: 12em;
      transform: rotate(180deg);
      transition: 0.5s;
      .openBtn {
        .MuiSvgIcon-root {
          margin-right: -0.2em;
        }
      }
      .closebtn {
        .MuiSvgIcon-root {
            margin-right:-0.3em
        }
      }
    }
    .MuiTypography-body1 {
      font-family: NeoSansArabicMedium !important;
    }
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
}

.my_interviews_icon {
  height: 1.6em;
}
