
  .main {
    margin-left: 290px; /* Same as the width of the sidenav */
    margin-right: 40px;
    margin-top: 30px;
    transition: margin-left .5s;
  }

  .rtl {
    .main {
        margin-right: 290px; /* Same as the width of the sidenav */
        margin-left: 40px !important;
        margin-top: 30px;
        transition: margin-right .5s;
      }
  }

   