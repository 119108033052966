:root {
    --company-primary-color: #808080;
    --company-transparent-color: rgba(172, 69, 86, 0.06);
    --company-secondary-color: #808080;
}
:export {
    primaryColor: #808080;
    secondaryColor :#808080;
    defaultPrimaryColor: #86364e;
    defaultSecondaryColor: #ea8685;
    TransparentColor: rgba(172, 69, 86, 0.06);
}