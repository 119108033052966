@import "../../../scss/variables";

.customNavbar {
  box-shadow:0px 0px 15px -5px rgba(0, 0, 0, 0.11);
  background-color: $primaryColor;
  margin-left: 250px;
  margin-right: 0;
  transition: all 0.5s ease 0s;
  .nav-container-width.container {
    max-width: 96% !important;
  }
  .navbar-light .navbar-nav .nav-link {
    color: white !important;
    cursor: pointer;
  }
  .navItem {
    text-decoration: none;
    color: white;
    margin-right: 1.5em;
    margin-left: 1.5em;
    align-self: center;
    .navbar-nav {
      color:white;
      text-decoration: none !important;
    }
    &:hover {
      color:white;
    }
  }

  .signNav {
    font-size: 16px;
  }

  .navbar-brand{
    color: white !important;
  }
  

  .langBtn {
    color: white !important;
    text-decoration: none;
    font-family: NeoSansArabicBold;
  }

  .border_right {
    background-color: lightgray;
    height: 1.5em;
    width: 0.1em;
    display: flex;
    align-items: center;
    align-self: center;
  }
  .navbar-toggler {
    border: 0 !important;
    outline: none;
  }

  .dropdown {
    button {
      cursor: pointer;

      &.btn-primary {
        background-color: transparent !important;
        color: $darkTextColor !important;
        &:hover {
          color: $darkTextColor !important;
        }
        &:focus,
        &:active {
          background-color: transparent !important;
          color: $darkTextColor !important;
          outline: none;
          box-shadow: none !important;
        }
      }
    }
  }

  .dropdown-menu {
    top: 200%;
    transition: 0.3s all ease-in-out;
    width: 50%;
    margin: 0 auto;
    margin-bottom: 1em;
  }
  .dropdown:hover > .dropdown-menu {
    top: 100%;
    border: 0;
    box-shadow: 0px 2px 34px 0px rgba(127, 50, 63, 0.07);
  }

  .dropdown > .dropdown-toggle:active {
    outline: none;
  }

  .dropdown > .dropdown-toggle:focus {
    outline: none;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: $primaryColor;
  }

  .dropdown-item:hover {
    background-color: $primaryColor;
    color: white;
  }
}


@media only screen and (max-width: 800px) {
  .signNav {
    font-size: 12px !important;
  }
  .navItem {
    line-height: 2em;
  }
  .navbar-brand{
    font-size: 1rem !important;
  }
  
}

.rtl {
  .customNavbar {
    margin-right: 250px;
    margin-left: 0 !important;
  }
}
