@import "../../../scss/variables";
.drawerShown {
  display: none !important;
}

.listItem {
  width: 90% !important;
  margin: 0 auto !important;
  color: $primaryColor !important;
  &:hover,
  &.Mui-selected,
  &.Mui-selected:hover {
    border: 0 !important;
    background: $secondaryColor !important;
    color: $primaryColor;
    border-radius: 5px;
    box-shadow: 0px 1px 17px 0px rgba(127, 50, 63, 0.22);
    .MuiTypography-body1 {
      color: white !important;
    }
  }
}

.logoSideNav {
  position: absolute;
  bottom: 0;
  height: 4em;
}

@media screen and (max-width: 900px) {
  .sidenav {
    display: none !important;
  }

  .drawerShown {
    display: block !important;
  }

  .navbar {
    margin: 0 !important;
  }

  #main {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
}

.sidemenu-logo {
  height: 5em;
}

.my_interviews_icon {
  height: 1.6em;
}

.icons_img{
  height: 2em;
  width: 2em;
}