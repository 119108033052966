@import '../../../scss/variables';

.avatarName {
    background-color: $secondaryColor ;
    color: white;
    text-transform: uppercase;
    border-radius: 50%;
    padding: 1em;
    height: 2.5em;
    width: 2.5em;
}

.avatarImage {
    border-radius: 50%;
    padding: 1em;
    height: 2.5em;
    width: 2.5em;
    background-size: cover;
    background-position: center;
}

.fullName {
    text-transform: capitalize;
    text-align: justify;
}