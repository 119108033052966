/* ============= Import your fonts here ============= */
@each $fontFamily in $fontFamilies {
  @each $fontExtention in $fontExtentions {
    @font-face {
      font-family: #{$fontFamily};
      src: url("../assets/Fonts/englishFont/#{$fontFamily}.#{$fontExtention}");
      font-display: swap;
    }
    .#{$fontFamily} {
      font-family: #{$fontFamily};
    }
  }
}

@each $fontFamily in $ArabicFontFamilies {
  @each $fontExtention in $fontExtentions {
    @font-face {
      font-family: #{$fontFamily};
      src: url("../assets/Fonts/arabicFont/#{$fontFamily}.#{$fontExtention}");
      font-display: swap;
    }
    .#{$fontFamily} {
      font-family: #{$fontFamily};
    }
  }
}
